<template>
  <div class="row justify-content-center align-items-md-center">
    <div class="col-md-8">
      <div class="card full-height bg-ligth">
        <div class="card-header">
          <h3 class="mb-0">Salas</h3>
        </div>
        <div class="card-body">
          <b-table
            :fields="['nome', 'sigla', 'descricao', 'setor', 'local', 'acoes']"
            :items="lista_sala"
            :per-page="perPage"
            :current-page="currentPage"
            id="sala-table"
            class="
              table
              table-head-custom
              table-vertical-center
              table-head-bg
              table-borderless
            "
            show-empty
            empty-text="Nenhum registro encontrado!"
          >
            <template #head(acoes)><span></span></template>
            <template #cell(acoes)="{ item }">
              <div class="text-right w-100">
                  <button
                    v-show="lista_permissoes_filial.u_Sala"
                    @click="atualizar(item)"
                    class="btn btn-icon btn-light btn-sm mx-1"
                    v-b-tooltip.hover
                    title="Editar registro"
                  >
                    <i class="far fa-edit text-primary"></i>
                  </button>

                <button
                  v-show="lista_permissoes_filial.lock_Sala"
                  @click="confirm(item)"
                  class="btn btn-icon btn-light btn-sm mx-1"
                  v-b-tooltip.hover
                  title="Bloquear/Desbloquear registro"
                >
                  <i class="fas fa-unlock-alt text-danger"></i>
                </button>
              </div>
            </template>
          </b-table>
          <b-pagination
            v-model="currentPage"
            :total-rows="lista_sala.length"
            :per-page="perPage"
            aria-controls="sala-table"
          >
          </b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";

export default {
  data() {
    return {
      currentPage: 1,
      perPage: 10,
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Salas" }]);
  },
  created() {
    this.listar_sala();
  },
  computed: {
    lista_sala() {
      return this.$store.state.configEmpresa.lista_sala;
    },
    lista_permissoes_filial() {
      return this.$store.state.sistema.lista_permissoes_filial;
    },
    mensagem_alert() {
      return this.$store.state.configEmpresa.mensagem_alert;
    },
  },
  methods: {
    async listar_sala() {
      this.$store.dispatch("configEmpresa/MudarPreloader", true);
      await this.$store.dispatch("configEmpresa/listar_sala").finally(() => {
        this.$store.dispatch("configEmpresa/MudarPreloader", false);
        this.$store.dispatch("configEmpresa/atualizar", "");
      });
    },
    atualizar(value) {
      this.$router.push({ name: "createSala" });
      this.$store.dispatch("configEmpresa/atualizar", value);
    },
    async confirm(value) {
      await this.$confirm({
        title: "Estas seguro?",
        message: `Esta ação bloqueara esta Sala no sistema?`,
        button: {
          no: "No",
          yes: "Si",
        },
        callback: (confirm) => {
          if (confirm) {
            this.bloquear(value);
          }
        },
      });
    },
    async bloquear(value) {
      await this.$store.dispatch(
        "configEmpresa/delete_vinculo_sala_especialidade",
        value
      );
      this.alertMessage(this.mensagem_alert.tipo, this.mensagem_alert.message);
    },
    alertMessage(tipo, mensagem) {
      this.$fire({
        title: "Aviso",
        text: mensagem,
        type: tipo,
        timer: 3000,
      }).then((r) => {
        console.log(r.value);
      });
    },
  },
};
</script>

<style scoped>
.lista:hover {
  background-color: rgba(167, 183, 255, 0.705);
}
</style>